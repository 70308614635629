export let EmployeePositions = Object.freeze({
    AlMutawali: 1,
    SecretaryGeneral: 2,
    DeputySecretaryGeneral: 3,
    BoardOfDirectors: 4,
    HeadsOfDepartments: 5,
    AssistantHeadOfDepartment: 6,
    CenterManager: 7,
    DivisionOfficial: 8,
    AssistantCenterManager: 9,
    UnitOfficial: 10
});

export let Positions = [
    {
        type: "AlMutawali",
        value: 1
    },
    {
        type: "SecretaryGeneral",
        value: 2
    },
    {
        type: "DeputySecretaryGeneral",
        value: 3
    },
    {
        type: "BoardOfDirectors",
        value: 4
    },
    {
        type: "HeadsOfDepartments",
        value: 5
    },
    {
        type: "AssistantHeadOfDepartment",
        value: 6
    },
    {
        type: "CenterManager",
        value: 7
    },
    {
        type: "DivisionOfficial",
        value: 8
    },
    {
        type: "AssistantCenterManager",
        value: 9
    },
    {
        type: "UnitOfficial",
        value: 10
    }
];
