var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('Page-Header',{attrs:{"title":_vm.$t('alerts.alertToPositions'),"icon":"mdi-office-building"}}),(_vm.disabled)?_c('v-system-bar',{attrs:{"color":"orange darken-1","height":"30"}},[_vm._v(" "+_vm._s(_vm.$t("alerts.ui.cannotEditAlertAfterCreateIt"))+" ")]):_vm._e(),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"disabled":_vm.disabled,"hide-details":"","outlined":_vm.outlined,"dense":_vm.dense,"rules":_vm.rules,"label":_vm.$t('alerts.title')},model:{value:(_vm.editedAlert.title),callback:function ($$v) {_vm.$set(_vm.editedAlert, "title", $$v)},expression:"editedAlert.title"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('tiptap-vuetify',{attrs:{"card-props":{
                                outlined: _vm.outlined
                            },"extensions":_vm.extensions,"disabled":_vm.disabled,"toolbar-attributes":{
                                color: _vm.$vuetify.theme.dark ? '' : '#EEEEEE'
                            }},model:{value:(_vm.editedAlert.details),callback:function ($$v) {_vm.$set(_vm.editedAlert, "details", $$v)},expression:"editedAlert.details"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"outlined":_vm.outlined,"items":_vm.sendersEmployees,"disabled":_vm.disabled,"item-value":"employeeGuid","item-text":"employeeName","rules":_vm.rules,"hide-details":"","label":_vm.$t('alerts.ui.alertAs'),"dense":_vm.dense},on:{"change":_vm.onSenderChange},model:{value:(_vm.editedAlert.senderEmployeeGuid),callback:function ($$v) {_vm.$set(_vm.editedAlert, "senderEmployeeGuid", $$v)},expression:"editedAlert.senderEmployeeGuid"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.positions,"disabled":_vm.disabled,"outlined":_vm.outlined,"hide-details":"","item-value":"value","item-text":"type","chips":"","label":_vm.$t('alerts.ui.alertToPositions'),"clearable":"","rules":_vm.listRules,"deletable-chips":"","multiple":"","dense":_vm.dense,"small-chips":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                                var item = ref.item;
                                var index = ref.index;
return [(
                                        index === 0 ||
                                            index === 1 ||
                                            index === 2
                                    )?_c('v-chip',{attrs:{"disabled":_vm.disabled}},[_c('span',[_vm._v(_vm._s(item.type))])]):_vm._e(),(index === 3)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.alertToPositions.length - 3)+" "+_vm._s(_vm.$t("others"))+") ")]):_vm._e()]}}]),model:{value:(_vm.alertToPositions),callback:function ($$v) {_vm.alertToPositions=$$v},expression:"alertToPositions"}},[_c('template',{slot:"prepend-item"},[_c('v-btn',{attrs:{"text":"","block":""},on:{"click":_vm.selectAllPositions}},[_vm._v(_vm._s(_vm.$t("selectAll")))])],1)],2)],1)],1),_c('v-row',[(
                            _vm.alertFiles != undefined &&
                                (_vm.alertFiles != null) &
                                    (_vm.alertFiles.length > 0)
                        )?_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"black--text"},[_vm._v(" "+_vm._s(_vm.$t("alerts.alertAttachments"))+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"overflow-y-auto",attrs:{"max-height":"300","elevation":"0"}},[_c('v-card-text',[_c('v-row',_vm._l((_vm.alertFiles),function(file){return _c('v-col',{key:file.name,attrs:{"cols":"12","md":"4"}},[_c('Download-File',{attrs:{"iconWidth":50,"fileSizeFormat":file.size,"disabled":_vm.disabled,"onDelete":_vm.openDeleteAttachmentDialog,"onClose":_vm.closeDeleteAttachmentDialog,"file":file,"fileUrl":file.path}})],1)}),1)],1)],1)],1)],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-file-input',{attrs:{"small-chips":"","outlined":_vm.outlined,"show-size":"","dense":_vm.dense,"label":_vm.$t('attachments'),"multiple":"","disabled":_vm.disabled,"hide-details":""},on:{"click:clear":_vm.clearAttachments,"change":_vm.attachmentsInputChanged},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                                var index = ref.index;
                                var text = ref.text;
return [_c('v-chip',{attrs:{"close":""},on:{"click:close":function($event){return _vm.deleteAttachmentChip(index)}}},[_vm._v(" "+_vm._s(text)+" ")])]}}]),model:{value:(_vm.currFiles),callback:function ($$v) {_vm.currFiles=$$v},expression:"currFiles"}})],1)],1)],1)],1),_c('v-card-actions',[(
                    _vm.isInRole(_vm.roles.alert.create) ||
                        _vm.isInRole(_vm.roles.alert.update)
                )?_c('v-btn',{attrs:{"disabled":!_vm.valid || _vm.disabled,"loading":_vm.saveLoading,"min-width":"80","color":"primary"},on:{"click":_vm.saveAlert}},[_c('v-icon',[_vm._v("mdi-content-save-outline")]),_vm._v(" "+_vm._s(!_vm.disabled ? _vm.$t("save") : _vm.$t("save") + " " + _vm.$t("attachments"))+" ")],1):_vm._e()],1),_c('confirm-dialog',{attrs:{"openDialog":_vm.deleteFileDialog,"onClicked":_vm.deleteAttachment,"onClose":_vm.closeDeleteAttachmentDialog,"toolBarColor":"red darken-2"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }